import React, { useCallback, useContext, useEffect, useState } from "react";
import HyperLogo from "../../Images/HyperLogo.png";
import styled from "styled-components";
import Clock from "./Clock";
import PlayerSky from "./PlayerSky";
import SubsSky from "../Subs/SubsSky";
import EndHalf from "./EndHalf";
import moment from "moment";
import { Button, ScrollBar } from "../../Theme/Hyper";
import { UserContext } from "../../contexts/UserContext";
import { APIContext } from "../../contexts/APIContext";
import { ControlContext } from "../../contexts/ControlContext";
import Select from "react-select";
import ClockCountdown from "./ClockCountdown";
import WinPredictor from "./WinPredictor";
import EPCRStandings from "./Tables/EPCRStandings";
import URCStandings from "./Tables/URCStandings";
import Teams from "./Teams";
import Attendance from "../Popup/Attendance";
import Tags from "../Tags/Tags";
import { ClockContext } from "../../contexts/ClockContext";
import KickPredictor from "../KickPredictor/KickPredictor";
import Pundits from "./Pundits";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { UIContext } from "../../contexts/UIContext";

const Main = styled.div`
  display: flex;

  align-items: center;
  width: 100%;
  padding-top: 0.5em;
  background-color: #202731;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
`;
const Logo = styled.img`
  height: auto;
  width: 50%;
`;
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const SplitGraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 45%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const CurrentGraphic = styled.div`
  margin-top: auto;
  width: 100%;
  height: 167px;
  background-color: #181d25;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 12px;
  margin: 0px;
  padding-bottom: 10px;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const NavButton = styled(Button)`
  margin: 0.3em;
  margin-top: 0.5em;
  padding: 5px;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  color: ${(props) => (props.selected ? "#1d1d1d" : "")};
  font-size: 0.73em;
`;

const Profile = styled.div`
  width: 100%;
  height: 35px;
  background-color: #202731;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0px 4px 12px -20px rgb(0 0 0 / 20%),
    0px 8px 20px 2px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #030304;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const GraphicsList = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  max-height: 560px;
  overflow-y: auto;
  width: 100%;
  align-items: center;
`;

const SelectContainer = styled(Select)`
  min-width: 200px;
  margin-bottom: 0.5em;
  width: 90%;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;
const Title = styled.div`
  display: flex;
  margin-top: 10px;
`;

const Username = styled.div`
  max-width: 200px;
  overflow: hidden;
`;

const DropdownStyles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#0e1219",
      "&:hover": {
        color: "#db0a41",
      },
    };
  },
  control: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "#fff" };
  },
};

const DECISIONS = [
  "Failing to release",
  "in from the side",
  "collapsing the scrum",
  "offside",
  "high tackle",
  "obstruction",
  "dissent",
  "advantage",
  "try",
  "no try",
  "TMO decision",
];
export default function RightSideBarSky({
  game,
  updateGame,
  previewGraphic,
  graphicInPreview,
  animateGraphic,
  animateOffGraphic,
  updateGraphic,
  teams,
  selectedPlayer,
  players,
  stadiums,
  sky,
  squads,
  countries,
  standings,
  kits,
  clearPreview,
  homeManager,
  awayManager,
  setShowAttendance,
  homeSubsMade,
  setHomeSubsMade,
  awaySubsMade,
  setAwaySubsMade,
}) {
  const { clockType } = useContext(ClockContext);
  const { user, signOut } = useContext(UserContext);
  const { getSquad } = useContext(APIContext);
  const { popout, rightBarView, setRightBarView, lastRowStatSelected } =
    useContext(UIContext);
  const { graphics } = useContext(GraphicsContext);
  const { engines, selectedEngine, setSelectedEngine } =
    useContext(ControlContext);

  const [matchStatsView, setMatchStatsView] = useState(0);
  const [timelineAnimated, setTimelineAnimate] = useState(0);
  const [showScoreLine, setShowScoreLine] = useState(false);
  const [showFTScoreLine, setShowFTScoreLine] = useState(false);
  const [decision, setDecision] = useState("");
  const [homeSquad, setHomeSquad] = useState([]);
  const [awaySquad, setAwaySquad] = useState([]);
  useEffect(() => {
    if (game && homeSquad.length === 0) {
      getSquad({
        teamId: game?.home_team?._id,
        seasonId: game.season?._id,
      }).then((data) => {
        setHomeSquad(data);
      });
    }
    if (game && awaySquad.length === 0) {
      getSquad({
        teamId: game?.away_team?._id,
        seasonId: game.season?._id,
      }).then((data) => {
        setAwaySquad(data);
      });
    }
  }, [game]);
  let period = [...(game?.live_data?.periods || [])].sort(
    (a, b) => b.number - a.number
  )[0];

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      updateShowScoreLine();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const updateShowScoreLine = useCallback(() => {
    let period_temp = [...(game?.live_data?.periods || [])].sort(
      (a, b) => b.number - a.number
    )[0];

    let mins =
      clockType === "manual"
        ? parseInt(document.getElementById("manual_minutes")?.innerText)
        : parseInt(document.getElementById("stadium_minutes")?.innerText);
    if (period_temp.number === 1 && mins > 37) {
      setShowScoreLine(true);
    } else {
      setShowScoreLine(false);
    }
    if (period_temp.number === 2 && mins > 78) {
      setShowFTScoreLine(true);
    } else {
      setShowFTScoreLine(false);
    }
  }, [game]);

  useEffect(() => {
    if (selectedPlayer) {
      setRightBarView(0);
    }
  }, [selectedPlayer]);

  return (
    <Main>
      {!window.hideHeader && (
        <Profile>
          <Username>{user?.username}</Username>
          <Button
            style={{ border: "1px solid #fff", marginLeft: "auto" }}
            onClick={() => {
              signOut();
            }}
          >
            Sign Out
          </Button>
        </Profile>
      )}
      {window.ENV?.REACT_APP_SKY !== "true" &&
        process.env.REACT_APP_SKY !== "true" &&
        engines?.length > 1 && (
          <SelectContainer
            styles={DropdownStyles}
            setSize={true}
            value={{
              value: selectedEngine,
              label: engines?.find((e) => e.key === selectedEngine)?.name,
            }}
            options={engines.map((item, index) => {
              return {
                value: item.key,
                label: item.name,
              };
            })}
            onChange={(e) => {
              setSelectedEngine(e.value);
            }}
            placeholder="Round"
          />
        )}
      <Logo src={HyperLogo}></Logo>
      <Clock
        game={game}
        teams={teams}
        updateGame={updateGame}
        previewGraphic={previewGraphic}
        animateGraphic={animateGraphic}
        animateOffGraphic={animateOffGraphic}
        updateGraphic={updateGraphic}
        sky={sky}
      />
      <Buttons>
        <NavButton
          selected={rightBarView === 0}
          onClick={() => setRightBarView(0)}
        >
          MAIN
        </NavButton>
        {(window.ENV?.REACT_APP_SKY === "true" ||
          process.env.REACT_APP_SKY === "true") && (
          <NavButton
            selected={rightBarView === 4}
            onClick={() => setRightBarView(4)}
          >
            TEAMS
          </NavButton>
        )}
        {(window.ENV?.REACT_APP_SKY === "true" ||
          process.env.REACT_APP_SKY === "true") && (
          <NavButton
            selected={rightBarView === 5}
            onClick={() => setRightBarView(5)}
          >
            MATCH STATS
          </NavButton>
        )}
        <NavButton
          selected={rightBarView === 1}
          onClick={() => setRightBarView(1)}
        >
          SUBS
        </NavButton>
        {game?.competition?.competition_type === "union" && (
          <NavButton
            selected={rightBarView === 3}
            onClick={() => setRightBarView(3)}
          >
            DECISIONS
          </NavButton>
        )}

        {/* {(window.ENV?.REACT_APP_CATALANS === "true" ||
          process.env.REACT_APP_CATALANS === "true") && (
          <NavButton
            selected={rightBarView === 2}
            onClick={() => setRightBarView(2)}
          >
            TABLES
          </NavButton>
        )} */}

        <NavButton
          selected={rightBarView === 6}
          onClick={() => setRightBarView(6)}
        >
          PUNDITS
        </NavButton>
      </Buttons>
      {selectedPlayer && rightBarView === 0 && (
        <PlayerSky
          player={selectedPlayer}
          game={game}
          previewGraphic={previewGraphic}
          teams={teams}
          sky={sky}
          countries={countries}
          period={period}
        />
      )}
      {rightBarView === 1 && (
        <SubsSky
          sky={sky}
          countries={countries}
          animateGraphic={animateGraphic}
          previewGraphic={previewGraphic}
          animateOffGraphic={animateOffGraphic}
          game={game}
          teams={teams}
          players={players}
          homeSubs={game.lineup?.home?.filter((p) => p.sub_order)}
          awaySubs={game.lineup?.away?.filter((p) => p.sub_order)}
          period={period}
          homeSubsMade={homeSubsMade}
          setHomeSubsMade={setHomeSubsMade}
          awaySubsMade={awaySubsMade}
          setAwaySubsMade={setAwaySubsMade}
        />
      )}
      {rightBarView === 0 && (
        <GraphicsList>
          {!selectedPlayer && period.number === 1 && !period.kick_off && (
            <>
              {/* pre game */}
              <GraphicButton
                onClick={() => {
                  previewGraphic("Versus", {
                    game: game,
                    teams: teams,
                    sky: sky,
                    period: period?.number,
                    from_button: true,
                  });
                }}
              >
                L3 ident & comms (1400+)
              </GraphicButton>
            </>
          )}

          {!selectedPlayer &&
            ((period.number === 2 && !period.kick_off) || showScoreLine) && (
              <>
                {/* half time  */}
                <GraphicButton
                  onClick={() => {
                    previewGraphic("Score", {
                      game: game,
                      type: "HT",
                    });
                  }}
                >
                  HT Score
                </GraphicButton>
              </>
            )}

          {!selectedPlayer && period.number === 1 && period.kick_off && (
            <>
              {/* first half  */}

              <GraphicButton
                onClick={() => {
                  previewGraphic("Versus", {
                    game: game,
                    teams: teams,
                    sky: sky,
                    period: period?.number,
                    from_button: true,
                  });
                }}
              >
                L3 ident & comms (1400+)
              </GraphicButton>

              <GraphicButton
                onClick={() => {
                  previewGraphic("FFScore", {
                    game: game,
                    teams: teams,
                    sky: sky,
                    half:
                      period.number === 1 ||
                      (period.number === 2 && period.kick_off === null)
                        ? 1
                        : period.number === 2 ||
                          (period.number === 3 && period.kick_off === null)
                        ? 2
                        : period.number,
                    show_half: false,
                    no_scorers: true,
                    home_squad: homeSquad,
                    away_squad: awaySquad,
                  });
                }}
              >
                Scoreboard (500)
              </GraphicButton>
              <GraphicButton
                onClick={() => {
                  setShowAttendance(true);
                }}
              >
                Set Attendance {game?.attendance || ""}
              </GraphicButton>

              {game?.competition?.variables?.logging_type !== "manual" && (
                <GraphicButton
                  onClick={() => {
                    previewGraphic("FFStats", {
                      game: game,
                      type: "HT",
                      half: 1,
                      lastRowStatSelected,
                    });
                  }}
                >
                  FF Match Stats (501)
                </GraphicButton>
              )}
              {game?.competition?.variables?.logging_type === "manual" && (
                <GraphicButton
                  onClick={() => {
                    previewGraphic("FFStatsLoggers", {
                      game: game,
                      type: "HT",
                      half: 1,
                    });
                  }}
                >
                  FF Match Stats Manual (501)
                </GraphicButton>
              )}
              <GraphicButton
                onClick={() => {
                  previewGraphic("CBSINBIN", {
                    game: game,
                    period: period?.number,
                    clockType,
                    push_up:
                      graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                      graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                  });
                }}
              >
                CB Sin Bin (7051)
              </GraphicButton>
              <GraphicButton
                onClick={() => {
                  previewGraphic("CBCHALLENGES", {
                    successful: game?.home_challenges?.successful ?? 0,
                    unsuccessful: game?.home_challenges?.unsuccessful ?? 0,
                    inconclusive: game?.home_challenges?.inconclusive ?? 0,
                    team: game?.home_team,
                    push_up:
                      graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                      graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                  });
                }}
              >
                CB Home Challenges (7058)
              </GraphicButton>

              <GraphicButton
                onClick={() => {
                  previewGraphic("CBCHALLENGES", {
                    successful: game?.away_challenges?.successful ?? 0,
                    unsuccessful: game?.away_challenges?.unsuccessful ?? 0,
                    inconclusive: game?.away_challenges?.inconclusive ?? 0,
                    team: game?.away_team,
                    push_up:
                      graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                      graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                  });
                }}
              >
                CB Away Challenges (7058)
              </GraphicButton>

              <GraphicButton
                onClick={() => {
                  previewGraphic("CBCHALLENGESCOMPARISON", {
                    game: game,
                    period: period?.number,
                    clockType,
                    push_up:
                      graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                      graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                  });
                }}
              >
                CB Challenges (7059)
              </GraphicButton>
            </>
          )}

          {!selectedPlayer && period.number === 2 && !period.kick_off && (
            <>
              {/* half time  */}
              <GraphicButton
                onClick={() => {
                  previewGraphic("FFScore", {
                    game: game,
                    teams: teams,
                    sky: sky,
                    half:
                      period.number === 1 ||
                      (period.number === 2 && period.kick_off === null)
                        ? 1
                        : period.number === 2 ||
                          (period.number === 3 && period.kick_off === null)
                        ? 2
                        : period.number,
                    show_half: false,
                    no_scorers: true,
                    home_squad: homeSquad,
                    away_squad: awaySquad,
                  });
                }}
              >
                Scoreboard (500)
              </GraphicButton>
              <GraphicButton
                onClick={() => {
                  setShowAttendance(true);
                }}
              >
                Set Attendance {game?.attendance || ""}
              </GraphicButton>

              {game?.competition?.variables?.logging_type !== "manual" && (
                <GraphicButton
                  onClick={() => {
                    previewGraphic("FFStats", {
                      game: game,
                      type: "HT",
                      half: 1,
                      lastRowStatSelected,
                    });
                  }}
                >
                  FF Match Stats (501)
                </GraphicButton>
              )}
              {game?.competition?.variables?.logging_type === "manual" && (
                <GraphicButton
                  onClick={() => {
                    previewGraphic("FFStatsLoggers", {
                      game: game,
                      type: "HT",
                      half: 1,
                    });
                  }}
                >
                  FF Match Stats Manual (501)
                </GraphicButton>
              )}
              <Row>
                <SplitGraphicButton
                  onClick={() => {
                    animateGraphic("ScoreBug", {
                      game: game,
                      status: "HT",
                    });
                  }}
                >
                  HT Bug ON
                </SplitGraphicButton>
                <SplitGraphicButton
                  onClick={() => {
                    animateOffGraphic("ScoreBug", {
                      game: game,
                      status: "HT",
                    });
                  }}
                >
                  HT Bug OFF
                </SplitGraphicButton>
              </Row>
              <Tags
                game={game}
                previewGraphic={previewGraphic}
                homeSquad={homeSquad}
                awaySquad={awaySquad}
              />
              <GraphicButton
                onClick={() => {
                  previewGraphic("Versus", {
                    game: game,
                    teams: teams,
                    sky: sky,
                    period: period?.number,
                    from_button: true,
                  });
                }}
              >
                L3 ident & comms (1400+)
              </GraphicButton>
            </>
          )}

          {!selectedPlayer &&
            ((period.number === 3 && !period.kick_off) || showFTScoreLine) && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("Score", {
                    game: game,
                    type: "FT",
                  });
                }}
              >
                FT Score
              </GraphicButton>
            )}
          {!selectedPlayer && period.number === 2 && period.kick_off && (
            <>
              {/* 2nd half  */}

              <GraphicButton
                onClick={() => {
                  previewGraphic("Versus", {
                    game: game,
                    teams: teams,
                    sky: sky,
                    period: period?.number,
                    from_button: true,
                  });
                }}
              >
                L3 ident & comms (1400+)
              </GraphicButton>

              <GraphicButton
                onClick={() => {
                  previewGraphic("FFScore", {
                    game: game,
                    teams: teams,
                    sky: sky,
                    half:
                      period.number === 1 ||
                      (period.number === 2 && period.kick_off === null)
                        ? 1
                        : period.number === 2 ||
                          (period.number === 3 && period.kick_off === null)
                        ? 2
                        : period.number,
                    show_half: false,
                    no_scorers: true,
                    home_squad: homeSquad,
                    away_squad: awaySquad,
                  });
                }}
              >
                Scoreboard (500)
              </GraphicButton>
              <GraphicButton
                onClick={() => {
                  setShowAttendance(true);
                }}
              >
                Set Attendance {game?.attendance || ""}
              </GraphicButton>

              {game?.competition?.variables?.logging_type !== "manual" && (
                <GraphicButton
                  onClick={() => {
                    previewGraphic("FFStats", {
                      game: game,
                      type: "FT",
                      half: 2,
                      lastRowStatSelected,
                    });
                  }}
                >
                  FF Match Stats (501)
                </GraphicButton>
              )}
              <GraphicButton
                onClick={() => {
                  previewGraphic("CBSINBIN", {
                    game: game,
                    period: period?.number,
                    clockType,
                    push_up:
                      graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                      graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                  });
                }}
              >
                CB Sin Bin (7051)
              </GraphicButton>
              <GraphicButton
                onClick={() => {
                  previewGraphic("CBCHALLENGES", {
                    successful: game?.home_challenges?.successful ?? 0,
                    unsuccessful: game?.home_challenges?.unsuccessful ?? 0,
                    inconclusive: game?.home_challenges?.inconclusive ?? 0,
                    team: game?.home_team,
                    push_up:
                      graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                      graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                  });
                }}
              >
                CB Home Challenges (7058)
              </GraphicButton>

              <GraphicButton
                onClick={() => {
                  previewGraphic("CBCHALLENGES", {
                    successful: game?.away_challenges?.successful ?? 0,
                    unsuccessful: game?.away_challenges?.unsuccessful ?? 0,
                    inconclusive: game?.away_challenges?.inconclusive ?? 0,
                    team: game?.away_team,
                    push_up:
                      graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                      graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                  });
                }}
              >
                CB Away Challenges (7058)
              </GraphicButton>

              <GraphicButton
                onClick={() => {
                  previewGraphic("CBCHALLENGESCOMPARISON", {
                    game: game,
                    period: period?.number,
                    clockType,
                    push_up:
                      graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                      graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                  });
                }}
              >
                CB Challenges (7059)
              </GraphicButton>
            </>
          )}

          {!selectedPlayer &&
            ((period.number === 3 && !period.kick_off) || showFTScoreLine) && (
              <>
                {/* Full Time */}

                <GraphicButton
                  onClick={() => {
                    previewGraphic("FFScore", {
                      game: game,
                      teams: teams,
                      sky: sky,
                      half:
                        period.number === 1 ||
                        (period.number === 2 && period.kick_off === null)
                          ? 1
                          : period.number === 2 ||
                            (period.number === 3 && period.kick_off === null)
                          ? 2
                          : period.number,
                      show_half: false,
                      no_scorers: true,
                      home_squad: homeSquad,
                      away_squad: awaySquad,
                    });
                  }}
                >
                  Scoreboard (500)
                </GraphicButton>
                <GraphicButton
                  onClick={() => {
                    setShowAttendance(true);
                  }}
                >
                  Set Attendance {game?.attendance || ""}
                </GraphicButton>

                {game?.competition?.variables?.logging_type !== "manual" && (
                  <GraphicButton
                    onClick={() => {
                      previewGraphic("FFStats", {
                        game: game,
                        type: "HT",
                        half: 1,
                        lastRowStatSelected,
                      });
                    }}
                  >
                    FF Match Stats (501)
                  </GraphicButton>
                )}
                <Row>
                  <SplitGraphicButton
                    onClick={() => {
                      animateGraphic("ScoreBug", {
                        game: game,
                        status: "FT",
                        golden_point: period?.number >= 4,
                        popout: popout?.find((p) => p?.game_id === game?._id),
                      });
                    }}
                  >
                    FT Bug ON
                  </SplitGraphicButton>
                  <SplitGraphicButton
                    onClick={() => {
                      animateOffGraphic("ScoreBug", {
                        game: game,
                        status: "FT",
                        golden_point: period?.number >= 4,
                      });
                    }}
                  >
                    FT Bug OFF
                  </SplitGraphicButton>
                </Row>
                <Tags
                  game={game}
                  previewGraphic={previewGraphic}
                  homeSquad={homeSquad}
                  awaySquad={awaySquad}
                />
              </>
            )}

          {!selectedPlayer && period.number > 2 && period.kick_off && (
            <>
              {/* Golder points */}
              <GraphicButton
                onClick={() => {
                  previewGraphic("Versus", {
                    game: game,
                    teams: teams,
                    sky: sky,
                    period: period?.number,
                    from_button: true,
                  });
                }}
              >
                L3 ident & comms (1400+)
              </GraphicButton>

              <GraphicButton
                onClick={() => {
                  previewGraphic("FFScore", {
                    game: game,
                    teams: teams,
                    sky: sky,
                    half:
                      period.number === 1 ||
                      (period.number === 2 && period.kick_off === null)
                        ? 1
                        : period.number === 2 ||
                          (period.number === 3 && period.kick_off === null)
                        ? 2
                        : period.number,
                    show_half: false,
                    no_scorers: true,
                    home_squad: homeSquad,
                    away_squad: awaySquad,
                  });
                }}
              >
                Scoreboard (500)
              </GraphicButton>
              <GraphicButton
                onClick={() => {
                  setShowAttendance(true);
                }}
              >
                Set Attendance {game?.attendance || ""}
              </GraphicButton>

              {game?.competition?.variables?.logging_type !== "manual" && (
                <GraphicButton
                  onClick={() => {
                    previewGraphic("FFStats", {
                      game: game,
                      type: "FT",
                      half: 2,
                      lastRowStatSelected,
                    });
                  }}
                >
                  FF Match Stats (501)
                </GraphicButton>
              )}
            </>
          )}

          {!selectedPlayer && period.number > 3 && !period.kick_off && (
            <>
              {/* GP Full Time */}
              <GraphicButton
                onClick={() => {
                  previewGraphic("Score", {
                    game: game,
                    type: "FT",
                  });
                }}
              >
                FT Score
              </GraphicButton>
              <GraphicButton
                onClick={() => {
                  previewGraphic("FFScore", {
                    game: game,
                    teams: teams,
                    sky: sky,
                    half:
                      period.number === 1 ||
                      (period.number === 2 && period.kick_off === null)
                        ? 1
                        : period.number === 2 ||
                          (period.number === 3 && period.kick_off === null)
                        ? 2
                        : period.number,
                    show_half: false,
                    no_scorers: true,
                    home_squad: homeSquad,
                    away_squad: awaySquad,
                  });
                }}
              >
                Scoreboard (500)
              </GraphicButton>
              <GraphicButton
                onClick={() => {
                  setShowAttendance(true);
                }}
              >
                Set Attendance {game?.attendance || ""}
              </GraphicButton>

              {game?.competition?.variables?.logging_type !== "manual" && (
                <GraphicButton
                  onClick={() => {
                    previewGraphic("FFStats", {
                      game: game,
                      type: "HT",
                      half: 1,
                      lastRowStatSelected,
                    });
                  }}
                >
                  FF Match Stats (501)
                </GraphicButton>
              )}
              <Row>
                <SplitGraphicButton
                  onClick={() => {
                    animateGraphic("ScoreBug", {
                      game: game,
                      status: "FT",
                      golden_point: period?.number >= 4,
                      popout: popout?.find((p) => p?.game_id === game?._id),
                    });
                  }}
                >
                  FT Bug ON
                </SplitGraphicButton>
                <SplitGraphicButton
                  onClick={() => {
                    animateOffGraphic("ScoreBug", {
                      game: game,
                      status: "FT",
                      golden_point: period?.number >= 4,
                    });
                  }}
                >
                  FT Bug OFF
                </SplitGraphicButton>
              </Row>
              <Tags
                game={game}
                previewGraphic={previewGraphic}
                homeSquad={homeSquad}
                awaySquad={awaySquad}
              />
            </>
          )}
        </GraphicsList>
      )}

      {rightBarView === 2 && (
        <GraphicsList>
          {game?.competition?.name === "Challenge Cup" && (
            <EPCRStandings previewGraphic={previewGraphic} game={game} />
          )}
          {game?.competition?.name === "URC" && (
            <URCStandings previewGraphic={previewGraphic} game={game} />
          )}
          {game?.competition?.name !== "Challenge Cup" &&
            game?.competition?.name !== "URC" && (
              <Standings previewGraphic={previewGraphic} game={game} />
            )}
        </GraphicsList>
      )}
      {rightBarView === 3 && (
        <GraphicsList>
          {DECISIONS.map((d) => {
            return (
              <GraphicButton
                green={d === decision}
                onClick={() => {
                  if (decision === d) {
                    setDecision("");
                    updateGraphic("CLOCK", {
                      type: "DECISION",
                      decision: "",
                    });
                  } else {
                    setDecision(d);
                    updateGraphic("CLOCK", {
                      type: "DECISION",
                      decision: d,
                    });
                  }
                }}
              >
                {d}
              </GraphicButton>
            );
          })}
        </GraphicsList>
      )}
      {rightBarView === 4 && (
        <Teams
          game={game}
          previewGraphic={previewGraphic}
          teams={teams}
          period={period}
          players={players}
          homeManager={homeManager}
          awayManager={awayManager}
          homeSquad={homeSquad}
          awaySquad={awaySquad}
        />
      )}
      {rightBarView === 5 && (
        <GraphicsList>
          <Buttons>
            <NavButton
              selected={matchStatsView === 0}
              onClick={() => {
                setMatchStatsView(0);
              }}
            >
              GENERAL
            </NavButton>
            <NavButton
              selected={matchStatsView === 1}
              onClick={() => {
                setMatchStatsView(1);
              }}
            >
              POSITIONAL
            </NavButton>
          </Buttons>
          {matchStatsView === 0 &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("MatchStats", {
                    game: game,
                  });
                }}
              >
                CB - Match Stats
              </GraphicButton>
            )}
          {matchStatsView === 0 &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <Row>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("CarryingStats", {
                      game: game,
                    });
                  }}
                >
                  CB - Carrying stats
                </GraphicButton>
              </Row>
            )}
          {matchStatsView === 0 &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <Row>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("AttackingStats", {
                      game: game,
                    });
                  }}
                >
                  CB - Attacking stats
                </GraphicButton>
              </Row>
            )}
          {matchStatsView === 0 &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <Row>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("DefendingStats", {
                      game: game,
                      push_up:
                        graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                        graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                    });
                  }}
                >
                  CB - Defending stats
                </GraphicButton>
              </Row>
            )}
          {matchStatsView === 0 &&
            timelineAnimated !== 1 &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) && (
              <GraphicButton
                onClick={() => {
                  setTimelineAnimate(1);
                  animateGraphic("TryTimeline", {
                    game: game,
                    half: 1,
                  });
                }}
              >
                1st Half Try Timeline
              </GraphicButton>
            )}
          {matchStatsView === 0 &&
            timelineAnimated === 1 &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) && (
              <GraphicButton
                red
                onClick={() => {
                  setTimelineAnimate(0);
                  animateOffGraphic("TryTimeline", {
                    game: game,
                    half: 1,
                  });
                }}
              >
                Animate Off
              </GraphicButton>
            )}
          {matchStatsView === 0 &&
            timelineAnimated !== 2 &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) && (
              <GraphicButton
                onClick={() => {
                  setTimelineAnimate(2);
                  animateGraphic("TryTimeline", {
                    game: game,
                    half: 2,
                  });
                }}
              >
                2nd Half Try Timeline
              </GraphicButton>
            )}
          {matchStatsView === 0 &&
            timelineAnimated === 2 &&
            ((period?.kick_off && period?.number === 1) ||
              period?.number >= 2) && (
              <GraphicButton
                red
                onClick={() => {
                  setTimelineAnimate(0);
                  animateOffGraphic("TryTimeline", {
                    game: game,
                    half: 2,
                  });
                }}
              >
                Animate Off
              </GraphicButton>
            )}
          {matchStatsView === 1 && (
            <GraphicButton
              onClick={() => {
                previewGraphic("PositionMatchStats", {
                  game: game,
                  position: "outside_backs",
                  push_up:
                    graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                    graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                });
              }}
            >
              CB - Outside Backs Match Stats
            </GraphicButton>
          )}
          {matchStatsView === 1 &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("PositionMatchStats", {
                    game: game,
                    position: "props",
                    push_up:
                      graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                      graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                  });
                }}
              >
                CB - Middles Match Stats
              </GraphicButton>
            )}
          {matchStatsView === 1 &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("PositionMatchStats", {
                    game: game,
                    position: "half_backs",
                    push_up:
                      graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                      graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                  });
                }}
              >
                CB - Half Backs Match Stats
              </GraphicButton>
            )}
          {matchStatsView === 1 &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("PositionMatchStats", {
                    game: game,
                    position: "back_row",
                    push_up:
                      graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                      graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                  });
                }}
              >
                CB - 2nd Row Match Stats
              </GraphicButton>
            )}
          {matchStatsView === 1 &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <GraphicButton
                onClick={() => {
                  previewGraphic("PositionMatchStats", {
                    game: game,
                    position: "hooker",
                    push_up:
                      graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                      graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                  });
                }}
              >
                CB - Hooker Match Stats
              </GraphicButton>
            )}
          {matchStatsView === 1 && <Title>CB Left Edge Match Stats</Title>}
          {matchStatsView === 1 &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <Row>
                <SplitGraphicButton
                  onClick={() => {
                    previewGraphic("EdgeMatchStats", {
                      game: game,
                      position: "left_edge",
                      team: game?.home_team,
                      push_up:
                        graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                        graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                    });
                  }}
                >
                  Home
                </SplitGraphicButton>
                <SplitGraphicButton
                  onClick={() => {
                    previewGraphic("EdgeMatchStats", {
                      game: game,
                      position: "left_edge",
                      team: game?.away_team,
                      push_up:
                        graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                        graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                    });
                  }}
                >
                  Away
                </SplitGraphicButton>
              </Row>
            )}
          {matchStatsView === 1 && <Title>CB Right Edge Match Stats</Title>}
          {matchStatsView === 1 &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <Row>
                <SplitGraphicButton
                  onClick={() => {
                    previewGraphic("EdgeMatchStats", {
                      game: game,
                      position: "right_edge",
                      team: game?.home_team,
                      push_up:
                        graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                        graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                    });
                  }}
                >
                  Home
                </SplitGraphicButton>
                <SplitGraphicButton
                  onClick={() => {
                    previewGraphic("EdgeMatchStats", {
                      game: game,
                      position: "right_edge",
                      team: game?.away_team,
                      push_up:
                        graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                        graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                    });
                  }}
                >
                  Away
                </SplitGraphicButton>
              </Row>
            )}
          {matchStatsView === 1 && <Title>CB Middles Match Stats</Title>}
          {matchStatsView === 1 &&
            (process.env.REACT_APP_SKY === "true" ||
              window.ENV?.REACT_APP_SKY === "true") && (
              <Row>
                <SplitGraphicButton
                  onClick={() => {
                    previewGraphic("EdgeMatchStats", {
                      game: game,
                      position: "middles",
                      team: game?.home_team,
                      push_up:
                        graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                        graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                    });
                  }}
                >
                  Home
                </SplitGraphicButton>
                <SplitGraphicButton
                  onClick={() => {
                    previewGraphic("EdgeMatchStats", {
                      game: game,
                      position: "middles",
                      team: game?.away_team,
                      push_up:
                        graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                        graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                    });
                  }}
                >
                  Away
                </SplitGraphicButton>
              </Row>
            )}
        </GraphicsList>
      )}
      {rightBarView === 6 && (
        <Pundits previewGraphic={previewGraphic} game={game} />
      )}
      {matchStatsView === 0 && selectedPlayer && (
        <KickPredictor
          game={game}
          previewGraphic={previewGraphic}
          player={selectedPlayer}
        />
      )}

      {graphicInPreview && (
        <CurrentGraphic
          style={{ bottom: window.hideHeader === true ? "130px" : "40px" }}
        >
          {graphicInPreview.name}
          <GraphicButton
            style={{ height: "40px", backgroundColor: "#383f4a" }}
            onClick={() => {
              clearPreview(graphicInPreview.name);
            }}
          >
            CLEAR PVW (CTRL + Q)
          </GraphicButton>

          <GraphicButton
            green
            style={{ height: "40px" }}
            onClick={() => {
              animateGraphic(graphicInPreview.name);
            }}
          >
            IN (F8) or (-)
          </GraphicButton>
          <GraphicButton
            red
            style={{ height: "40px" }}
            onClick={() => {
              animateOffGraphic(graphicInPreview.name);
            }}
          >
            OUT (F9) or (*)
          </GraphicButton>
        </CurrentGraphic>
      )}
    </Main>
  );
}

function Standings({ previewGraphic, teams, game }) {
  const { getTeams } = useContext(APIContext);

  return (
    <>
      <GraphicButton
        onClick={async () => {
          let teams = await getTeams({});
          console.log(teams);

          let data = await fetch(
            (window.ENV?.REACT_APP_RUGBY_API ||
              process.env.REACT_APP_RUGBY_API) + "/superleague/table"
          ).then((res) => res.json());

          let table = Object.keys(data?.data?.table)
            .filter((key) => key !== "deductions")
            .map((key) => {
              return { ...data?.data?.table[key], position: key };
            });

          previewGraphic("Standings", {
            data: table,
            teams,
            game,
            highlight: true,
          });
        }}
      >
        Standings (Highlight)
      </GraphicButton>
      <GraphicButton
        onClick={async () => {
          let teams = await getTeams({});
          console.log(teams);

          let data = await fetch(
            (window.ENV?.REACT_APP_RUGBY_API ||
              process.env.REACT_APP_RUGBY_API) + "/superleague/table"
          ).then((res) => res.json());
          let table = Object.keys(data?.data?.table)
            .filter((key) => key !== "deductions")
            .map((key) => {
              return { ...data?.data?.table[key], position: key };
            });

          previewGraphic("Standings", {
            data: table,
            teams,
            game,
          });
        }}
      >
        Standings (No Highlight)
      </GraphicButton>
    </>
  );
}
